import React from "react";

function flatBookmarks(bookmarks) {
  let ret = [];
  (bookmarks || []).forEach((i) => {
    if (i.url) {
      ret.push(i);
      return;
    }
    if (Array.isArray(i.children) && i.children.length > 0) {
      ret = ret.concat(flatBookmarks(i.children));
    }
  });
  return ret;
}

function chunk(arr, size) {
  const ret = [];
  let chunk = [];
  arr.forEach((item, i) => {
    if (i % size === 0) {
      ret.push(chunk);
      chunk = [];
    }
    chunk.push(item);
  });
  if (chunk.length > 0) {
    ret.push(chunk);
  }
  return ret;
}

function Bookmark({ bookmark }) {
  return (
    <a
      className="link col-lg-2"
      target="_blank"
      rel="noopener noreferrer"
      href={bookmark.url}
    >
      <img src="/favicon.ico" alt="icon" className="link-icon" />
      <span className="link-title">{bookmark.title}</span>
    </a>
  );
}

function BookmarkCategory({ bookmarks, title }) {
  const links = flatBookmarks(bookmarks);
  const chunks = chunk(links, 6);
  return (
    <div className="category" id={title}>
      <div className="category-title">{title}</div>
      <div className="category-content container-fluid">
        {chunks.map(
          (chunk, i) =>
            chunk.length > 0 && (
              <div key={i} className="row">
                {chunk.map((link) => (
                  <Bookmark key={link.url} bookmark={link} />
                ))}
              </div>
            )
        )}
      </div>
    </div>
  );
}

function Header() {
  return (
    <div className="header">
      <div className="container">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              class="nav-item nav-link active"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Home
            </a>
            <a
              class="nav-item nav-link"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Profile
            </a>
            <a
              class="nav-item nav-link"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-contact"
              role="tab"
              aria-controls="nav-contact"
              aria-selected="false"
            >
              Contact
            </a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="nav-contact"
            role="tabpanel"
            aria-labelledby="nav-contact-tab"
          >
            ...
          </div>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div className="footer text-muted p-3">
      由{" "}
      <a href="https://chrome.google.com/webstore/detail/%E9%85%B7%E4%B9%A6%E7%AD%BE/hfhlcfcnaohckjbbbknibnadmbobfnbn">
        酷书签
      </a>{" "}
      强力驱动，<a href="https://vcoders.cn/topic/12">意见反馈</a>
    </div>
  );
}

export default function Home({ trees = [] }) {
  return (
    <div className="home">
      <div className="main">
        <ul className="side-nav">
          {trees.map(
            (t) =>
              !t.url && (
                <li key={t.title} className="side-nav-item">
                  <a href={`#${t.title}`}>{t.title}</a>
                </li>
              )
          )}
        </ul>
        <div className="content">
          {trees.map(
            (t) =>
              !t.url && (
                <BookmarkCategory
                  title={t.title}
                  bookmarks={t.children}
                  key={t.title}
                />
              )
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}
