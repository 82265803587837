import React, { useEffect } from 'react';
import useFetch from 'use-http';
import Home from './Home';

import './App.css';

function App() {
  const identity = window.location.pathname.substring(1)
  const {loading, data} = useFetch('/api/data/' + identity, [])
  const tree = (data && JSON.parse(data.bookmarks || JSON.stringify({ children: [] }))) || {};
  useEffect(() => {
    if (tree.title) {
      document.title = tree.title;
    }
  }, [tree])
  if (loading || !data) {
    return <div className="loading">数据加载中...</div>;
  }
  if (tree.children.length === 0) {
    return <div className="loading">没有任何书签，请先在浏览器插件中选择一个要同步的书签目录。</div>
  }
  const commonLinkFolder = {
    title: '常用链接',
    children: [],
  }
  tree.children.forEach(node => {
    if (node.url) {
      commonLinkFolder.children.push(node);
    }
  });
  tree.children.unshift(commonLinkFolder);
  return (
    <div className="App">
      <Home trees={tree.children} />
    </div>
  );
}

export default App;
